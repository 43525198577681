<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";

export default {
	name: "ComprasContainer",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
	}),
	computed: {
		...mapState(["usuario"]),
	},
	methods: {
		montarMenu() {
			let m = [];

			if (this.permissao("compras_cadastro_produtos")) {
				m.push({
					titulo: "Cadastro de produtos",
					titulo_app: "Cadastro de produtos",
					icon: "mdi-sync",
					to: "/compras",
					tooltip: "Cadastro de peso e medida de produtos",
				});
			}

			if (this.permissao("compras_manutencao_precos")) {
				m.push({
					titulo: "Manutenção de Preços por Produto",
					titulo_app: "Manutenção de Preços",
					icon: "mdi-file-pdf-box",
					to: "/compras/produto",
					tooltip:
						"Alterar preço de produtos com base na margem e custo",
				});
			}

			if (this.permissao("compras_manutencao_precos")) {
				m.push({
					titulo: "Manutenção de Preços por Fornecedor",
					titulo_app: "Manutenção de Preços",
					icon: "mdi-file-pdf-box",
					to: "/compras/fornecedor",
					tooltip:
						"Alterar preço de produtos por fornecedor com base na margem e custo",
				});
			}

			if (this.permissao("compras_manutencao_precos")) {
				m.push({
					titulo: "Produtos em Atualização",
					titulo_app: "Produtos em Atualização",
					icon: "mdi-sync",
					to: "/compras/fila/atualizacao",
					tooltip:
						"Fila de processamento de atualização de produtos em alteração",
				});
			}

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
};
</script>

<style scoped>
</style>